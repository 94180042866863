<template>
	<section class="tabbed-screen" :class="size">
		<div class="screen-header">
			<div class="tabs" v-for="(item, index) in tabs" :key="index">
				<a
					v-on:click="activetab = index"
					v-bind:class="[activetab === index ? 'active' : '']"
					>{{ item }}</a
				>
			</div>
		</div>
		<div class="screen-content">
			<div v-for="(item, index) in tabs" :key="index">
				<slot
					:style="{ padding: 30 }"
					class="tabcontent"
					:name="index"
					v-if="activetab === index"
				/>
			</div>
		</div>
		<slot></slot>
	</section>
</template>

<script>
import { getUrlParams } from '@/utilities'
export default {
	name: 'tabbed-screen',
	props: {
		size: {
			type: String,
			default: 'large'
		},
		tabs: {
			type: Array,
			default: function() {
				return ['Tab 1', 'Tab 2', 'Tab 3']
			}
		}
	},
	data() {
		return {
			activetab: 0
		}
	},
	computed: {
		hasTitleSlot() {
			return !!this.$slots['title']
		},
		hasTableSlot() {
			return !!this.$slots['table']
		},
		hasChartSlot() {
			return !!this.$slots['chart']
		}
	},
	mounted() {
		const params = getUrlParams()
		if (params.tab) {
			this.activetab = parseInt(params.tab)
		}
	}
}
</script>

<style lang="scss">
@import '../../styles/variables';

.tabbed-screen {
	background: $white;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border-radius: 14px;
	overflow: hidden;
	width: 100% !important;
	max-width: 100% !important;
	&.small {
		max-width: 420px;
	}
	&.medium {
		max-width: 600px;
	}
	&.large {
		min-width: 1024px;
	}
	.screen-header {
		display: flex;
		padding: 0px;
		margin: -20px -20px 0px -20px;
		padding: 0px;
		padding-top: 20px;
		border-bottom: 1px solid $roundup-navy;
		.screen-title {
			flex-grow: 1;
			h1 {
				font-size: 24px;
				font-weight: 600;
				margin-top: 5px;
				margin-bottom: 0px;
				color: $roundup-medium-grey;
			}
		}
		.screen-buttons {
			flex-grow: 0;
			flex-shrink: 0;
			button {
				margin-top: 0px;
			}
		}
	}
	.screen-content {
		padding: 30px;
	}
	.screen-body {
		padding: 0px 40px;
	}
	.screen-table {
		position: relative;
		margin-left: -20px;
		margin-right: -20px;
		padding: 0px 40px;
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 48px;
			top: -1px;
			left: 0px;
			background: $roundup-lightest-grey;
			border-top: 1px solid $roundup-light-grey;
			border-bottom: 1px solid $roundup-lighter-grey;
		}
	}
	.el-table__header-wrapper {
		position: relative;
	}
	.el-table--enable-row-hover {
		.el-table__body {
			tr {
				td {
					transition: none;
					cursor: pointer;
				}
				&:hover {
					border-radius: 5px;
					overflow: hidden;
					td {
						&:first-child {
							border-top-left-radius: 10px;
							border-bottom-left-radius: 10px;
						}
						&:last-child {
							border-top-right-radius: 10px;
							border-bottom-right-radius: 10px;
						}
					}
					& > td {
						background: $roundup-navy;
						color: $white;
					}
				}
			}
		}
	}
	.screen-chart {
		background: $roundup-lightest-grey;
		margin-left: -20px;
		margin-right: -20px;
		padding: 0px 20px;
		border-bottom: 1px solid $roundup-light-grey;
		min-height: 300px;
		.chart-options {
			padding: 10px 20px 0px;
		}
	}
	.screen-footer {
		margin-top: 20px;
		text-align: center;
	}
	.container {
		// max-width: 620px;
		min-width: 420px;
		margin: 40px auto;
		font-size: 0.9em;
		color: #888;
	}
	.tabs {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		overflow: hidden;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: -1px;
		ul {
			list-style-type: none;
			margin-left: 20px;
		}
		a {
			float: left;
			cursor: pointer;
			padding: 12px 24px;
			transition: background-color 0.2s;
			border: 1px solid $roundup-navy;
			border-right: none;
			color: $roundup-dark-grey;
			background-color: $roundup-light-blue;
			border-radius: 10px 10px 0 0;
			font-weight: bold;
			&:last-child {
				border-right: 1px solid $roundup-navy;
			}
			&:hover {
				background-color: $roundup-navy;
				color: #fff;
			}
			&.active {
				background-color: #fff;
				color: $roundup-navy;
				border-bottom: 2px solid #fff;
				cursor: default;
			}
		}
	}
	.tabcontent {
		padding: 30px;
	}
}
.tabbed-screen .el-table__header-wrapper tr,
.tabbed-screen .el-table__header-wrapper th {
	background: $roundup-lightest-grey;
	text-transform: uppercase;
}
.tabbed-screen .el-table__header-wrapper tr .cell,
.tabbed-screen .el-table__header-wrapper th .cell {
	font-size: 12px;
}
</style>
