<template>
	<div class="screen-wrapper profile-view">
		<tabbed-screen
			class="profile-information"
			size="large"
			:tabs="['Team', 'Payout Settings']"
		>
			<div slot="0">
				<div slot="body">
					<users />
				</div>
			</div>
			<div slot="1">
				<v-card flat>
					<v-card-title class="pb-0" v-if="!showPaypal"
						>Stripe Account</v-card-title
					>
					<v-card-title class="pb-0" v-else
						>PayPal Account</v-card-title
					>
					<div class="pa-4 pt-0">
						<div
							v-if="roundupStripe && !showPaypal"
							class="alert alert-info"
							style="margin-top: 20px"
						>
							This is RoundUp App's Foundation account. Please
							feel free to change it to your stripe account by
							clicking "Change Stripe Account" below.
						</div>
						<div
							v-else-if="showPaypal"
							class="alert alert-info"
							style="margin-top: 20px"
						>
							You are setup to receive your donations via your
							PayPal account.
						</div>
						<div
							v-else
							class="alert alert-info"
							style="margin-top: 10px"
						>
							You can find/verify this account ID in your stripe
							account setting here:
							<a
								target="_blank"
								href="https://dashboard.stripe.com/settings/account"
								>https://dashboard.stripe.com/settings/account</a
							>. It's in the top right corner.
						</div>
						<div v-if="!showPaypal">
							<h3 style="margin-top: 20px; text-transform: none">
								Stripe Account ID
							</h3>
							<p>{{ institution.stripe_account }}</p>
						</div>
						<div v-else>
							<h3 style="margin-top: 20px; text-transform: none">
								PayPal Username
							</h3>
							<v-row style="justify-content: left; display: flex">
								<v-text-field
									style="max-width: 200px !important"
									prefix="@"
									v-model="paypal_username"
									placeholder="roundupapp"
									@input="() => (enablePaypalSave = true)"
								>
								</v-text-field>
								<Roundup-Button
									color="primary"
									:disabled="!enablePaypalSave"
									style="width: 150px !important"
									@click="onUpdatePaypal"
									:loading="paypalSaveLoading"
									>Save</Roundup-Button
								>
								<v-spacer></v-spacer>
							</v-row>
						</div>
						<br />
						<div :style="{ display: 'flex', flexDirection: 'row' }">
							<a
								v-if="!showPaypal"
								href="https://dashboard.stripe.com"
								target="_blank"
								:style="{ marginRight: '25px' }"
								><Roundup-Button color="primary"
									>View Stripe</Roundup-Button
								></a
							>
							<a
								v-else-if="showPaypal"
								href="https://paypal.com"
								target="_blank"
								:style="{ marginRight: '25px' }"
							>
								<Roundup-Button
									v-if="showPaypal"
									color="primary"
								>
									View PayPal
								</Roundup-Button>
							</a>
							<Roundup-Button
								:color="showPaypal ? 'white' : 'primary'"
								@click="changeStripeAccount"
								>Change {{ showPaypal ? ' To ' : '' }} Stripe
								Account
							</Roundup-Button>
							<Roundup-Button
								v-if="roundupStripe && !showPaypal"
								@click="showPaypal = true"
								color="white"
								class="ml-4"
							>
								Switch to PayPal Payouts
							</Roundup-Button>
						</div>
					</div>
				</v-card>
			</div>
		</tabbed-screen>
		<v-dialog v-model="showLocationDetails" width="500">
			<v-card>
				<v-card-title> Please Confirm Your Address </v-card-title>

				<v-card-text>
					<v-form ref="confirmForm">
						<v-row>
							<v-text-field
								v-model="institution.address.street_number"
								label="Street Number"
								required
								:rules="[
									() =>
										!!institution.address.street_number ||
										'This field is required'
								]"
							></v-text-field>
							<v-text-field
								v-model="institution.address.street_first"
								label="Street Name"
								required
								:rules="[
									() =>
										!!institution.address.street_first ||
										'This field is required'
								]"
							></v-text-field>
						</v-row>
						<v-row>
							<v-text-field
								v-model="institution.address.city"
								label="City"
								:rules="[
									() =>
										!!institution.address.city ||
										'This field is required'
								]"
								required
							></v-text-field>
							<v-spacer></v-spacer>
							<v-select
								:items="usStates"
								placeholder="Texas"
								item-text="name"
								item-value="code"
								label="State"
								:rules="[
									() =>
										!!institution.address.state ||
										'This field is required'
								]"
								v-model="institution.address.state"
								style="max-width: 212px !important"
							>
							</v-select>
						</v-row>
						<v-row>
							<v-text-field
								style="max-width: 212px !important"
								v-model="institution.address.zip"
								label="Zip Code"
								counter="5"
								required
								:rules="[
									() =>
										!!institution.address.zip ||
										'This field is required',
									v =>
										v && v.length !== 5
											? 'Enter a valid zip code'
											: true
								]"
								type="number"
								maxlength="5"
							></v-text-field>
							<v-spacer></v-spacer>
						</v-row>
					</v-form>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<Roundup-Button
						color="primary"
						text
						@click="showLocationDetails = false"
					>
						Close
					</Roundup-Button>
					<v-spacer></v-spacer>
					<Roundup-Button color="primary" @click="confirmAddress">
						Confirm
					</Roundup-Button>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'

import { mapActions, mapState, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { get, enums, getUrlParams } from '../../../utilities'
import { states } from '../../../utilities/enums'

import Screen from '../../ui/Screen'
import TabbedScreen from '../../ui/TabbedScreen'
import Modal from '../../ui/Modal'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import UiButton from '../../ui/Button'
import UiToggle from '../../ui/Toggle'
import Users from '../Users/UserListView'
import TokenList from '../../layout/TokenList'

import { LocationInput } from '../../ui'

import SmsTemplates from './SMSTemplates'
import Receipts from './Receipts.vue'

export default {
	name: 'profile-view',
	components: {
		Screen,
		UiInput,
		UiLabel,
		UiButton,
		TabbedScreen,
		Users,
		Modal,
		TokenList,
		UiToggle,
		SmsTemplates,
		LocationInput,
		Receipts
	},
	data() {
		return {
			isSaving: false,
			institution: {
				name: '',
				address: {
					street_first: null,
					city: null,
					state: null,
					zip: null,
					country: null,
					google: true
				}
			},
			paypal_username: null,
			enablePaypalSave: false,
			demo: localStorage.getItem('demo') || false,
			showLocationDetails: false,
			paypalSaveLoading: false,
			showPaypal: false
		}
	},
	title: {
		inner: 'Settings'
	},
	computed: {
		impact_statement() {
			return get(this.institution, 'impact_statement', '________________')
		},
		formattedAddress() {
			return this.institution.address &&
				this.institution.address.formatted_address
				? this.institution.address.formatted_address
				: ''
		},
		full_impact_statement() {
			return get(
				this.institution,
				'full_impact_statement',
				'________________'
			)
		},
		deeplink() {
			return 'https://roundup.app/p/' + this.institution.username
		},
		deepDonate() {
			return 'https://roundup.app/d/' + this.institution.username
		},
		roundupStripe() {
			let stripeAccounts = process.env.VUE_APP_stripeFallbackAccounts.split(
				','
			)

			return stripeAccounts.includes(this.institution.stripe_account)
		},
		usStates() {
			return states
		},
		...mapState({
			isLoading: ({ notify }) => notify.isFetching,
			me: ({ me }) => me.current
		}),
		...mapGetters(['role'])
	},
	methods: {
		...mapActions(['updateInstitution', 'setPaypalUsername']),
		/**
		 * When the location found
		 * @param {Object} addressData Data of the found location
		 * @param {Object} placeResultData PlaceResult object
		 * @param {String} id Input container ID
		 */
		getAddressData(addressData, placeResultData, id) {
			this.institution.address = addressData
			this.institution.address.google = true
		},

		async onUpdatePaypal() {
			this.paypalSaveLoading = true
			await this.setPaypalUsername(this.paypal_username)
			this.paypalSaveLoading = false
			this.setAlert({
				alertText: 'Your information has successfully been updated.',
				showAlert: true
			})
		},

		changeStripeAccount() {
			window.analytics.track('changeStripe')
			window.location.href =
				process.env.VUE_APP_stripeUrl +
				'&state=' +
				this.institution.uuid
		},
		update() {
			if (!this.institution.address.google) {
				this.showLocationDetails = true
				return
			}
			this.isSaving = true
			this.updateInstitution(this.institution)
				.then(() => {
					this.isSaving = false
					this.setAlert({
						alertText:
							'Your information has successfully been updated.',
						showAlert: true
					})
				})
				.catch(({ response }) => {
					this.isSaving = false
					this.setAlert({
						alertText: response.data.message,
						showAlert: true,
						alertColor: 'error'
					})
				})

			return false
		},
		activateDemo() {
			this.demo = !this.demo
			localStorage.demo = this.demo
			if (localStorage.demo) {
				console.log('Demo activated: ', localStorage.demo)
			}
		},
		confirmAddress() {
			if (!this.$refs.confirmForm.validate()) {
				return
			}

			const { address } = this.institution
			address.google = true
			address.formatted_address = `${get(
				address,
				'street_number',
				''
			)} ${get(address, 'street_first', '')} ${get(
				address,
				'city',
				''
			)}, ${get(address, 'state', '')} ${get(address, 'zip', '')}, USA`
			address.full_address = address.formatted_address
			address.latitude = null
			address.longitude = null
			this.$root.$emit('setLocation', address.formatted_address)
			this.institution.address = address

			this.showLocationDetails = false

			this.update()
		}
	},
	mounted() {
		const institution = cloneDeep(this.$store.state.institution.item)
		if (!institution.address) {
			institution.address = {
				street_first: null,
				city: null,
				state: null,
				zip: null,
				country: null,
				street_number: null
			}
		}
		this.institution = { ...this.institution, ...institution }
		this.institution.address.google = true
		if (!localStorage.demo) {
			localStorage.setItem('demo', false)
		}
		this.paypal_username = this.institution.paypal_username
		if (this.institution.paypal_username) {
			this.showPaypal = true
		}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';
.profile-view {
	width: 100%;
	min-width: 1024px;
	overflow-x: scroll;
}
.image-upload {
	position: relative;
	.image-upload-buttons {
		padding: 10px 0px;
		.upload-btn-wrapper {
			position: relative;
			overflow: hidden;
			display: inline-block;
			.btn {
				border: 1px solid $roundup-light-grey;
				color: $roundup-navy;
				background-color: #fff;
				padding: 8px 20px;
				border-radius: 8px;
				font-size: 14px;
				margin: 0px;
			}
			&:hover {
				.btn {
					background: $roundup-navy;
					color: $white;
					border-color: $roundup-navy;
					cursor: pointer;
				}
			}
			input[type='file'] {
				font-size: 100px;
				position: absolute;
				cursor: pointer;
				left: 0;
				top: 0;
				opacity: 0;
			}
		}
	}
	img {
		max-width: 100%;
		border-radius: 20px;
	}
	.image-cover {
		border-radius: 20px;
		overflow: hidden;
		position: relative;
	}
	.image-logo {
		width: 100px;
		position: absolute;
		left: 40px;
		top: 40px;
		border-radius: 20px;
		overflow: hidden;
	}
}
.profile-information {
	form {
		padding-top: 20px;
	}
	.row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 40px;
	}
	.flex-row {
		display: flex;
		flexdirection: row;
		alignitems: center;
	}
	h3 {
		text-transform: uppercase;
		font-size: 14px;
		margin-bottom: 0px;
		margin-top: 40px;
		color: #71788c;
		letter-spacing: 0;
		color: $roundup-charcoal;
	}
	p {
		color: $roundup-charcoal;
		margin-bottom: 0px;
	}
	#autoresizing {
		min-height: 2em;
		display: block;
		overflow: hidden;
		resize: none;
	}
}

.roundup-input {
	& > span {
		position: absolute;
		bottom: 0em;
		left: 0em;
		cursor: text;
		font-size: 75%;
		opacity: 1;
		transition: all 0.2s;
		color: $roundup-medium-grey;
	}
	.roundup-input-counter {
		position: absolute;
		right: 0px;
		bottom: 0px;
		color: $roundup-dark-grey;
		border-radius: 10px;
		padding: 3px 7px;
		font-size: 75%;
	}
}
.roundup-input input,
.roundup-input textarea {
	color: $roundup-navy;
	border-width: 0px;
	border-bottom: 1px solid $roundup-light-grey;
	width: 100%;
	font-size: $font-size;
	padding: 10px 0px;
	outline: none;
	-webkit-appearance: none;
	border-radius: 0px;
	padding-top: 1em;
}
.roundup-input input:hover,
.roundup-input textarea:hover,
.roundup-input input:focus,
.roundup-input textarea:focus {
	border-bottom-color: $roundup-navy;
	outline: none;
}
.roundup-input input::-webkit-input-placeholder,
.roundup-input textarea::-webkit-input-placeholder,
.roundup-input input::-ms-input-placeholder,
.roundup-input textarea::-ms-input-placeholder {
	color: $roundup-navy;
}
.roundup-input input::placeholder,
.roundup-input textarea::placeholder {
	opacity: 1;
	transition: all 0.2s;
}
.roundup-input input:placeholder-shown:not(:focus)::placeholder,
.roundup-input textarea:placeholder-shown:not(:focus)::placeholder {
	opacity: 0;
}
.roundup-input input:placeholder-shown:not(:focus) + *,
.roundup-input textarea:placeholder-shown:not(:focus) + * {
	font-size: 100%;
	color: $roundup-charcoal;
	bottom: 1.6em;
}
.roundup-input input:placeholder-shown:focus + *,
.roundup-input textarea:placeholder-shown:focus + * {
	color: $roundup-navy;
}
.roundup-input input:disabled,
.roundup-input textarea:disabled {
	background: $roundup-lightest-grey;
	color: $roundup-dark-grey;
}
.roundup-input input:disabled:hover,
.roundup-input textarea:disabled:hover {
	border-bottom-color: $roundup-light-grey;
}

.v-data-table {
	::v-deep .v-data-table-header {
		background-color: $roundup-bone !important;
		text-transform: uppercase !important;
	}
}
</style>
